@import "bootstrap/scss/functions";
/* Autotaks colors */
$superlightpurple-color: #b634b1;
$lightpurple-color: #93328e;
$purple-color: #800080;
$fp-purple: #7f1c8c;
$darkpurple-color: $fp-purple;
$blue-color: #337ab7;

$lightgreen-color: lightgreen;
$green-color: #34c18f;
$warn-color: #ffcc00;
$fogp-be-aware-color: #fff3cd;
$red-color: #e5554f;

$yellow-color: #ffd100;

$lightblack-color: #333333;
$darkergrey-color: #999999;
$darkgrey-color: #cccccc;
$grey-color: #e4e4e4;
$lightgrey-alt-color: #eeeeee;
$fp-blue-light: #f0f2f6;
$transparentgrey-color: rgba(#333333, .44);


$disabled-color: $lightblack-color;

$tile-more-background-color: $grey-color;
$tile-upload-background-color: $grey-color;

$home-background-color: $lightpurple-color;

$head-background-color: $darkpurple-color;
$head-active-color: #ffffff;

$btn-secondary-background-color: $fp-blue-light;
$btn-secondary-background-color-active: $lightgreen-color;
$btn-secondary-border-color: $green-color;

$btn-fop-background-color: $lightpurple-color;
$btn-fop-border-color: $lightpurple-color;

$btn-white-color: $lightpurple-color;


$menu-background-color: $purple-color;
$menu-color: #FFF;
$menu-active-color: #FFF; //#007bff;
$menu-inactive-color: #CCC;
$menu-divider-color: white;

$menu-background-color-sub: $lightpurple-color;
$menu-color-sub: white;
$menu-active-color-sub: white; //#007bff;
$menu-inactive-color-sub: #CCC;


$hover-color: $blue-color;

$border-line-color: $lightblack-color;

//Current design
//$sidebar-background: $fp-purple;
//$text-color:  #fff;


//New design - 
$fp-purple: rgb(38, 53, 93);
$fp-purple-medium: rgba(130, 136, 166);
$fp-purple-light: rgba(230, 230, 245);

$fp-blue: rgb(188, 228, 248);
$fp-blue-light: rgb(222, 242, 252);
$fp-blue-light-50: rgb(245,251,254);

$fp-red: rgb(245, 180, 180);
$fp-red-light: rgb(250, 220, 220);
$fp-red-light-50: tint-color($fp-red-light, 5%);

$fp-yellow: rgb(255, 230, 150);
$fp-yellow-light: rgb(255, 240, 200);
$fp-yellow-light-50: tint-color($fp-yellow-light, 5%);

$fp-green: rgb(170, 210, 175);
$fp-green-light: rgb(215, 235, 215);
$fp-green-light-50: tint-color($fp-green-light, 5%);

$disabled-color: $lightblack-color;

$fp-primary: rgb(60, 84, 147);
$fp-warning: shade-color($fp-yellow, 5%);
$fp-success: rgb(103, 175, 112);
$fp-danger: rgb(210, 68, 68);

