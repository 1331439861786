@import "/src/_vars.scss";

// Handle jumoing UI when opening modal window
.modal-open .topbar, .menu-open .topbar {
    //padding-right: 15px;
}

body.menu-open {
    overflow: hidden;
}
/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.ng-valid[required], .ng-valid.required, lc-autocomplete[required].ng-valid input, lc-autocomplete.required.ng-valid input {
    border-left: 5px solid $fp-success;
}

.ng-invalid:not(fieldset):not(form), lc-autocomplete[required].ng-invalid input, lc-autocomplete.required.ng-invalid input {
    border-left: 5px solid $fp-danger;
}

lc-autocomplete[required].ng-valid, lc-autocomplete[required].ng-invalid {
    border-left: none;
}

.header-view-text {
    font-size: 16px;
}

/* other generic styles */

//.jh-card {
//    padding: 1.5%;
//    margin-top: 20px;
//    border: none;
//}
//
//.error {
//    color: white;
//    background-color: red;
//}
//
//.pad {
//    padding: 10px;
//}
//
//.w-40 {
//    width: 40% !important;
//}
//
//.w-60 {
//    width: 60% !important;
//}
//
//.break {
//    white-space: normal;
//    word-break:break-all;
//}
//
//.readonly {
//    background-color: #eee;
//    opacity: 1;
//}
//
//.footer {
//    border-top: 1px solid rgba(0,0,0,.125);
//}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
    .alert {
        text-overflow: ellipsis;

        pre {
            background: none;
            border: none;
            font: inherit;
            color: inherit;
            padding: 0;
            margin: 0;
        }

        .popover pre {
            font-size: 10px;
        }
    }

    .toast {
        position: fixed;
        width: 100%;

        &.left {
            left: 5px;
        }

        &.right {
            right: 5px;
        }

        &.top {
            top: 55px;
        }

        &.bottom {
            bottom: 55px;
        }
    }
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
    @if $side == 'all' {
        .no-padding {
            padding: 0 !important;
        }
    } @else {
        .no-padding-#{$side} {
            padding-#{$side}: 0 !important;
        }
    }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
    width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
    dd {
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 768px) {
    .row.jh-entity-details > {
        dt {
            margin-bottom: 15px;
        }

        dd {
            border-bottom: 1px solid #eee;
            padding-left: 180px;
            margin-left: 0;
        }
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
    outline: none;
}

.itemText {
    font-size: 16px;
}

.itemText-sm {
    font-size: 14px;
}

/* flip table css or responsive tablecss */
@media only screen and (max-width: 1000px) {

    /*override table class of bootstrap*/
    #flip-table table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
    }
    /*
      #flip-table th,
      #flip-table td { vertical-align: top; margin: 0; }
      #flip-table th { text-align: left; }
      */

    #flip-table table {
        display: block;
        width: 100%;
        position: relative;
    }
    #flip-table thead {
        display: block;
        float: left;
    }
    #flip-table tbody {
        display: block;
        position: relative;
        width: auto;
        overflow-x: auto;
        white-space: nowrap;
    }
    #flip-table thead tr {
        display: block;
    }
    #flip-table th {
        display: block;
        text-align: right;
    }
    #flip-table tbody tr {
        display: inline-block;
        vertical-align: top;
    }
    #flip-table td {
        display: block;
        min-height: 1.20em;
        text-align: left;
    }

    /* borders */
    #flip-table th {
        border-left: 0;
        border-bottom: 0;
    }
    #flip-table td {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }
    #flip-table tbody tr {
        border-left: 1px solid #b8b8b8;
    }
    #flip-table th:last-child,
    #flip-table td:last-child {
        border-bottom: 1px solid #b8b8b8;
    }
}

/*
 * z-index definitions
 */
.z-1 {
    z-index: 50;
}
.z-2 {
    z-index: 55;
}
.z-3 {
    z-index: 60;
}
.z-4 {
    z-index: 65;
}
.z-5 {
    z-index: 70;
}

.btn-group {
    .btn {
        border-color: $fp-purple;
    }

    .btn-check:checked + .btn {
        background-color: $fp-green !important;
    }
}
