@import "/src/_vars.scss";

.dataTables_wrapper .row:first-child {
    //background-color: $fp-blue-light;
    //border-radius: 7px !important;
    //border: $fp-blue-light solid 1px;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 4px 0;
    height: 36px;
    position: relative;

    .col-sm-12:last-child {
        position: absolute;
        left: -12px;
        margin-top: 4px;

        input {
            border-radius: 0.25rem !important;
            border: $fp-blue solid 1px;
            width: 15rem;
            height: 30px;
            margin: 1px 0 0 0;
        }
    }

    .dataTables_filter {
        float: left;
    }
}


/* dataTables CSS modification & positioning */
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    left: 0 !important;
    content: "" !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 0 !important;
    content: "" !important;
}

table.dataTable.table {
    thead th, thead tr, th {
        position: relative;
        background-image: none !important;
        border-top: 1px solid #cccccc;
        vertical-align: top;
    }

    td {
        vertical-align: middle;
    }
    
    .table-cell {
        padding: 0 !important;
        
        .table-button {
            min-width: 48px;
            
        }
    }
}

table.dataTable thead th.sorting:before,
table.dataTable thead th.sorting_asc:before,
table.dataTable thead th.sorting_desc:before {
    position: absolute !important;
    top: 12px !important;
    right: 8px !important;
    display: block !important;
    font-family: FontAwesome !important;
}

table.dataTable thead th.sorting:before {
    content: "\f0dc" !important;
    font-size: 0.8em !important;
    padding-top: 0.12em !important;
    opacity: 0.4 !important;
}

table.dataTable thead th.sorting_asc:before {
    content: "\f0de" !important;
    opacity: 1 !important;
}

table.dataTable thead th.sorting_desc:before {
    content: "\f0dd" !important;
    opacity: 1 !important;
}

.datatable-actions {
    position: relative;
    top: 3px;
    right: 0;
    margin-right: 5px;
    z-index: 1;

    div.disabled {
        opacity: 0.4;
        cursor: not-allowed !important;
    }
}

.datatable-animated.datatable-loading {
    opacity: 0;
}

.datatable-animated.datatable-ready {
    opacity: 1;
    transition: opacity 800ms;
}
