@import "/src/_vars.scss";
/* after changing this file run 'yarn run webpack:build:vendor' or 'yarn install' or 'yarn run webpack:build' */
$fa-font-path: '~font-awesome/fonts';

/***************************
put Sass variables here:
eg $input-color: red;
****************************/

$table-hover-bg: $fp-blue-light;
$nav-link-color: $fp-primary;
$pagination-color:$fp-primary;
$pagination-active-bg: $fp-primary;

$theme-colors: (
    "primary": $fp-primary,
    "warning": $fp-warning,
    "success": $fp-success,
    "info":    $fp-blue,
    "danger":  $fp-danger
);

// In order to fixe jquery animate (https://github.com/twbs/bootstrap/issues/35536)
$enable-smooth-scroll: false;

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/font-awesome/scss/font-awesome';
@import './fonts/caravan/css/caravan.css';
@import './fonts/Quincy_CF_FP_43/css/quincy.css';

@each $color, $value in $theme-colors {
    .alert-#{$color} {
        @include alert-variant(shift-color($value, -40%), shift-color($value, -40%), #000);
    }
}

:root {
    --bs-body-font-size: 0.7rem;
}
