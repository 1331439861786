
@keyframes scale_10_percent_up {
    100% {
        transform: scale(1.1);
    }
}
@keyframes pulse_10_percent_up {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes pulse_30_percent_up {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes pulse_100_percent_up {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}