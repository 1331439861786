@import "/src/_vars.scss";
@import "/src/content/scss/animations.scss";

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body, h4, h5, .body-font {
    font-family: Verdana, Helvetica, sans-serif;
}

h1, h2, h3, .text-quincy {
    font-family: quincy-regular, Verdana, Helvetica, sans-serif;
}

h1 {
    margin-top: 16px;
    font-size: 2rem;
    margin-bottom: 12px;
}

h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

h3 {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

h4 {
    font-size: 0.9rem;
}

h5 {
    font-size: 0.7rem;
    font-weight: bold;
}

a {
    color: $fp-primary;
    text-decoration: none;
}

a.link-action {
    text-decoration: underline;
}
.card-header, .modal-header {
    h1, h2, h3, h4 {
        margin: 0;
    }
}

.btn, .btn-group {
    font-size: 0.7rem;
}

.form-control {
    height: auto;
}

.form-control:focus, .form-select:focus {
    border-color: #8397cc;
    box-shadow: 0 0 0 .2rem #3c549340
}

.form-control, .form-select, .input-group, .input-group-sm, .input-group-addon, .input-group-text, .col-form-label, .form-label, .form-check-label, label, .nav-item, .datatable-actions {
    font-size: 0.8rem;
}

.form-check-input {
    font-size: 1rem;
    margin-top: 2px;
}

.form-input-check-single {
    margin-top: 0.7rem;
}

input[type=checkbox].checkbox-lg {
    transform: scale(1.6);
}

input[type=radio].radio-lg {
    transform: scale(1.6);
}

label {
    margin-bottom: 0.2rem;
}

.col-form-label label {
    margin-bottom: 0;
}

/*
TODO remove all usage of form-control-sm
select.form-control-sm {
    font-size: 0.8rem;
*/

.monospace-text {
    font-family: monospace;
    font-size: 1.3em;
}

.col-form-label {
    text-align: right;
}

.col-form-label.label-left {
    text-align: left;
}

.nav.nav-tabs:not(.nav-tabs-vertical .nav.nav-tabs):not(.nav-tabs.lc-tabs) {
    padding-left: 15px;
    margin-left: -15px;
    margin-right: -12px;
    margin-bottom: 15px;
}

.nav-tabs-vertical {
    border-right: 1px solid #dee2e6;

    .nav-tabs {
        display: block;
        border: none;

        .nav-link:hover {
            border-color: transparent;
        }

        .nav-link {
            border: 1px solid transparent;
            border-radius: 0.25rem;
        }

        .nav-link:hover {

        }

        .nav-link.active {
            background-color: transparent;
            border: 1px solid #dee2e6
        }
    }
}

.icon-btn {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
}

.btn, .clickable {
    cursor: pointer;
}

.btn-sm, .form-control-sm {
    font-size: 0.7rem;
}

/** overwrite the color for active selected secondary-bottons */
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
    background-color: $fp-green !important;
    color: $fp-purple !important;
}

/** overwrite the color for secondary-buttons */
.btn-secondary {
    background-color: $btn-secondary-background-color !important;
    border-color: $darkergrey-color;
    color: $fp-purple !important;
}

.btn-white {
    color: $btn-white-color;
    background-color: #fff;
    min-width: 100px;
}

.full-width {
    width: 100%;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.btn-outline-secondary {
    --bs-btn-color: black;
    --bs-btn-border-color: #ced4da;
    --bs-btn-hover-border-color: #6c757d;
    --bs-btn-hover-bg: #6c757d;
    --bs-btn-active-border-color: #ced4da;
}

.btn-danger {
    --bs-btn-color: #fff;
}

.accordion-button {
    --bs-accordion-btn-color: var(--bs-primary);
    --bs-accordion-active-color: var(--bs-primary);
    --bs-accordion-active-bg: var($fp-blue-light);
}

.dropdown-menu.show button.dropdown-item {
    font-size: 0.8rem;
}

.selected-row {
    background-color: $fp-blue-light !important;
}

.fast-fa-spin {
    -webkit-animation: fa-spin 500ms infinite linear;
    animation: fa-spin 500ms infinite linear;
}

.modal.lc-fullscreen {
    .modal-header {
        background-color: $fp-green-light;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .modal-content {
        height: 100%;
    }
}

.modal.modal-wide .modal-dialog {
    max-width: 80%;
}

.modal-content {
    border-width: 0px;
    background-color: $fp-blue-light-50;
    border-color: $fp-blue-light;
}

/* Growl messages styling */
lc-bootstrap-growl {
    position: fixed;
    right: 0;
    margin: 15px;
    min-width: 150px;
    max-width: 500px;
    z-index: 2000;
    top: 36px;
}

.notify-badge {
    position: absolute;
    bottom: 0.8rem;
    left: 0.8rem;
    width: 1.0rem;
    line-height: 0.87rem;
    text-align: center;
    font-size: 0.5rem;
    border-radius: 50%;
    color: $fp-purple;
    background-color: #FFFFFF;
    border: 1px solid $fp-purple;
}

.notify-badge-white {
    @extend .notify-badge;
    background-color: #FFFFFF;
    color: $fp-purple;
}

.notify-badge-warning {
    @extend .notify-badge;
    background-color: darken($fp-yellow, 10%);
}

.notify-badge-danger {
    @extend .notify-badge;
    background-color: lighten($fp-danger, 5%);
    color: #EEEEEE;
    font-weight: bold;
}

.fop-page-spinner {
    font-size: 100px;
    margin-top: 50px;
    color: #93328E;
}

::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px #FFF inset !important;
    -webkit-box-shadow: 0 0 0 1000px #FFF inset !important;
    -webkit-text-fill-color: black !important;
}

.no-focus:focus {
    outline: none;
}

.alert-dismissible .close {
    padding: 0.1rem 0.2rem;
}

.sub-row-card {
    margin-top: 0px !important;
    border: 0px !important;
    padding: 5px;
    background-color: $fp-blue-light-50 !important;

    .spacer {
        margin-top: 10px;
    }

    .messages-card {
        display: -webkit-flex;
        display: flex;

        .messages-card-left {
            padding: 10px;
            display: flex;
            -webkit-flex: 1;
            flex: 1;
        }

        .messages-card-right {
            padding: 10px;
            padding-left: 30px;
            display: flex;
            -webkit-flex: 2;
            flex: 2;
        }

        .messages-card-action {
            width: 100%;
            padding: 10px;
        }

        .message-content {
            padding-left: 30px;
        }
    }
}

.table td.sub-row-td {
    border-top: none;
    padding: 0 !important;
}

.lc-fullscreen {
    background-color: #FFF;
}

.lc-fullscreen .modal-lg {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}

.lc-fullscreen .modal-dialog {
    margin: 0px;
}


.card {
    .card-header {
        background-color: rgba(0, 0, 0, 0.01);
    }

    background-color: $fp-blue-light-50;
    border: 1px solid $fp-purple-light;
}

.form-dirty .card {
    border-color: $fp-yellow;
}

.form-error .card {
    border-color: $red-color;
}

.overflow-x-hide {
    overflow-x: hidden !important;
}

.overflow-y-hide {
    overflow-y: hidden !important;
}

.overflow-hide {
    overflow: hidden !important;
}

.overflow-x-show {
    overflow-x: visible !important;
}

.overflow-y-show {
    overflow-y: visible !important;
}

.overflow-show {
    overflow: visible !important;
}

.overflow-x-scroll {
    overflow-x: scroll !important;
}

.overflow-y-scroll {
    overflow-y: scroll !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-auto {
    overflow: auto !important;
}

/**
 * If we have a table that is not a datatable, this is handy for styling
 */
.table-actions {
    div.disabled {
        opacity: 0.4;
        cursor: not-allowed !important;
    }
}

.btn.disabled, .btn:disabled {
    background-color: $disabled-color;
    border-color: $disabled-color;
    cursor: not-allowed !important;
    color: white;
}

.fa {
    margin-left: .3em;
    margin-right: .3em;
}

.fa-xl {
    font-size: 1.5em;
}

.dynamic-padding-bottom {
    margin-bottom: 4rem;
}

.table-button {
    padding: 0 !important;
    width: 48px;
    height: 48px;
    text-align: center;
    color: $fp-purple;
    vertical-align: middle;
    display: table-cell;
    line-height: 0.4rem;
    cursor: pointer;
}

.table-button-disabled, .table-button-disabled:active {
    color: rgba($fp-purple, 0.4) !important;
    cursor: not-allowed !important;
}

.ngb-tp-input-container {
    width: 3em !important;
}

//Faster scrolling on iOS https://github.com/twbs/bootstrap/issues/26631
.modal {
    -webkit-overflow-scrolling: touch;
}

.lc-fullscreen.attachment-gallery .modal-lg {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
}

.lc-fullscreen.attachment-gallery .modal-header {
    border-bottom: none;
}

.lc-fullscreen.attachment-gallery .modal-content {
    height: 100%;
}

.post-it {
    background: url("~content/images/post-it-row.png") repeat-y;
    line-height: 25px;
    max-width: 970px;
    outline: none;
    resize: none;
    padding: 2px 10px;
    border: none;
    text-align: left;
}

.list-thumbnail {
    width: 60px;
    height: 44px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.25rem;
    margin: 2px;
}

.vehicle-card-right-image.list-thumbnail {
    border-radius: unset;
    margin: unset;
}

.fa-spin-stop-ie-hack {
    -webkit-animation: none;
    animation: none;
}

//If we manually set ng-invalid then sometimes the element can have both classes
//In that case it should mark the element as invalid
.ng-valid.ng-invalid {
    border-left: 5px solid #e5554f;
}

.fixed-bottom-mobile-menu {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 1);
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
    z-index: 1000;
    color: $fp-purple;
    line-height: 14px;
    padding-top: 15px
}

.tooltip-large-text .tooltip-inner {
    max-width: 250px;
    text-align: left;
}

.text-start .tooltip-inner {
    text-align: left;
}

.dropdown-menu.show {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
}

.number-align {
    text-align: right !important;
    padding-right: 20px !important;
}

.table:not(.table-borderless) {
    th {
        border-top: 1px solid var(--bs-border-color);
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
}

.pagination {
    --bs-pagination-font-size: 0.875rem;
}
